@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.input {
  border-bottom-color: var(--attentionColor);
  position: relative;
}

.inputSuccess {
  padding-right: 32px;
  border-bottom-color: var(--successColor);
  position: relative;
}

.inputError {
  border-bottom-color: var(--failColor);
  position: relative;
}

.textarea {
  padding-right: 38px;
  width: 95%;
}

.counterLabel{
  position: absolute;
  right: 0px;

}