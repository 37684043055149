@import '../../styles/propertySets.css';
/* 
  Needed for Generic Carousel
*/

.emblaCarouselWrapper {
  display: grid;
  position: relative;
}

.carouselLargeTitle{
  @apply --marketplaceH1FontStyles;
  margin-bottom: 0px;
  font-size: 26px;

  @media (--viewportSmall) {
    font-size: 30px;
  }
}

.carouselSmallTitle{
  @apply --marketplaceH2FontStyles;
  margin-top: 0px;
  margin-bottom: 5px;
}
.carouselSubtitle{
  @apply --marketplaceH4FontStyles;
  margin-top: 5px;
  margin-bottom: 10px;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 25%; /*Each slide covers 80% of the viewport */
  position: relative;
}

.embla__button_prev {
  --webkit-tap-highlight-color: transparent;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  cursor: pointer;
  padding: 0px;
  appearance: none;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 2.1875rem;
  min-height: 2.1875rem;
  max-height: 2.1875rem;
  border-radius: 2.1875rem;
  pointer-events: all;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem, rgba(0, 0, 0, 0.05) 0px 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0px 0.625rem 0.625rem -0.3125rem;
  opacity: 0.85;
  border: 0.0625rem solid rgb(222, 226, 230);
  transition: opacity 150ms ease 0s;
  position: absolute;
  top: 50%;
  left: -1rem;
  
  &:hover {
    opacity: 1;
  }
}

.embla__button_next {
  --webkit-tap-highlight-color: transparent;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  cursor: pointer;
  padding: 0px;
  appearance: none;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 2.1875rem;
  min-height: 2.1875rem;
  max-height: 2.1875rem;
  border-radius: 2.1875rem;
  pointer-events: all;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem, rgba(0, 0, 0, 0.05) 0px 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0px 0.625rem 0.625rem -0.3125rem;
  opacity: 0.85;
  border: 0.0625rem solid rgb(222, 226, 230);
  transition: opacity 150ms ease 0s;
  position: absolute;
  top: 50%;
  right: -1rem;

  &:hover {
    opacity: 1;
  }
}