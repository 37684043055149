@import '../../styles/propertySets.css';

.root{}

.copyLinkButton {
  @apply --marketplaceButtonStylesSecondary;
 
  width: 142px;
  height: 54px;
  padding: 0;
  color: #000000;
 
  background: #FCFCFC;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 16px auto;
  @media (--viewportMedium) {
    /* display: flex;
    width: 260px;
    margin: auto 16px; */
  }
}

.mainWrapper {
  padding-bottom: 30px;
  margin-top: 15px;
  position: relative;
}

.wrapper {
  position: relative;
}

.linkCopiedText {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left:0;
  margin-top: 10px;
}