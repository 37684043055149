@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.carouselWrapper {
  margin: 0 auto;
  max-width: 1128px;
  padding: 0px;

  &:last-of-type{
    margin-bottom:36px;
  }
}

.listingCarouselWrapper {
  margin: 0 auto;
  max-width: var(--maxListingPageContentWidth);
  padding: 0px 24px;

  @media (--viewportLarge) {
    padding: 0px 36px;
  }

  &:last-of-type{
    margin-bottom:36px;
  }
}

.carouselLargeTitle{
  @apply --marketplaceH1FontStyles;
  margin-bottom: 0px;
}
.carouselSmallTitle{
  @apply --marketplaceH2FontStyles;
  margin-top: 0px;
  margin-bottom: 5px;
}
.carouselSubtitle{
  @apply --marketplaceH4FontStyles;
  margin-top: 5px;
  margin-bottom: 10px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */

}

.rootForImage {
  width: 100%;
  border-radius: var(--borderRadius);
}

.ratingValue {
  float: right;
  padding-right: 3px;
}
.ratingView {
  @apply --marketplaceH3FontStyles;
  float: right;
  position: absolute;
  right: 16px;
  top: 1px;
  /* z-index: 1000; */
  color: #ffdf00;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.ratingStar {
  padding-right: 3px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  min-height: 123px;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.originalPriceValue {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  text-decoration: line-through;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cancellationInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-left: 6px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-left: 6px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.durationInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-left: 6px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-left: 6px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listingCard {
  margin-bottom: 0px;
  flex-basis: 100%;
  cursor: pointer;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(100% - 12px);
    max-width: 100%;
    margin-right: 0px;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(100% - 16px);
    max-width: 100%;
    margin-right: 0px;
  }
}

.embla {
  overflow: hidden;
  position: relative;
  height: 400px;
}
.embla__container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 25%; /*Each slide covers 80% of the viewport */
  position: relative;
}

.embla__slide {
  /* margin-right: 24px; */
  flex: 0 0 320px;

}
.embla__slide:last-child {
  margin-right: 0px;
  /* margin-left: 10px; */
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
  left: 0.5rem;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 1rem;
  height: 1rem;
}

.embla__button_prev {
  --webkit-tap-highlight-color: transparent;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  cursor: pointer;
  padding: 0px;
  appearance: none;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 2.1875rem;
  min-height: 2.1875rem;
  border-radius: 2.1875rem;
  pointer-events: all;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem, rgba(0, 0, 0, 0.05) 0px 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0px 0.625rem 0.625rem -0.3125rem;
  opacity: 0.85;
  border: 0.0625rem solid rgb(222, 226, 230);
  transition: opacity 150ms ease 0s;
  position: absolute;
  top: 25%;
  left: 10px;

  &:hover {
    opacity: 1;
  }
}

.embla__button_next {
  --webkit-tap-highlight-color: transparent;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; */
  cursor: pointer;
  padding: 0px;
  appearance: none;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 2.1875rem;
  min-height: 2.1875rem;
  border-radius: 2.1875rem;
  pointer-events: all;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0.1875rem, rgba(0, 0, 0, 0.05) 0px 1.25rem 1.5625rem -0.3125rem, rgba(0, 0, 0, 0.04) 0px 0.625rem 0.625rem -0.3125rem;
  opacity: 0.85;
  border: 0.0625rem solid rgb(222, 226, 230);
  transition: opacity 150ms ease 0s;
  position: absolute;
  top: 25%;
  right: 10px;
  
  &:hover {
    opacity: 1;
  }
}

.emblaCarouselWrapper {
  display: grid;
  position: relative;
}