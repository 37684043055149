@import '../../styles/propertySets.css';

.root {}

.creditsRoot{
  display: inline-block;
}
.links {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-left: 0px;
    margin-bottom: 0;
  }
}

.creditsLinkWrapper{
  display: flex;
  &:hover{
    cursor: pointer;
  }
}
.credits {
  margin-top: 20px;
}

.creditsList {
 padding: 0 10px;
 @apply --marketplaceMessageFontStyles
}

.creditsItem {
  margin: 10px 0;
}

.closeButton{
  margin: 30px auto;
  max-width: 150px;
}
