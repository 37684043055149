@import '../../styles/propertySets.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
      /* font-weight: 600; */
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
}
.root {
  @apply --marketplaceButtonStyles;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.primaryButtonRoot {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.secondaryButtonRoot {
  @apply --marketplaceButtonStylesSecondary;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0 0 2px 0;
}

.inlineTextButtonRoot {
  @apply --marketplaceLinkStyles;

  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.inProgress {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--successColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--successColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

.signup,
.login {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @media (--viewportMedium) {
    @apply --marketplaceH4FontStyles;
    @apply --TopbarDesktop_label;
  }
}

/* Authentication */
.signupLink {
  @media (--viewportMedium) {
    @apply --TopbarDesktop_linkHover;
    flex-shrink: 0;
    height: 100%;
    padding: 0 12px 0 12px;

    color: var(--marketplaceColor);

    &:hover {
      color: var(--marketplaceColorDark);
      max-height: 72px;
    }
  }
}

.listingAffiliateLinkButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  margin-left: 0;
  margin-right: 0;

  width: 100%;
  height: 64px;

  /* @apply --marketplaceButtonStyles; */

  @media (--viewportMedium) {
    display: block;
    width: 312px;
  }
  @media (--viewportSmall) {
    width: 312px;
  }
}

.dashboardAffiliateLinkButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  margin-left: 0;
  margin-right: 0;

  width: 100%;
  height: 64px;
}

.affiliateModalContent {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: #4e4b66;
}

.affiliateModalLink {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: var(--marketplaceColor);
}

.affiliateLinkWrapper {
  display: flex;
  justify-content: left;
  padding: 0;
  margin: 20px 0 13px 0;

  @media (--viewportSmall) {
    width: 100%;
  }

  @media (--viewportMedium) {
    justify-content: center;
    width: auto;
    margin: 0;
  }
}
