@import '../../styles/propertySets.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.react-tel-input .form-control {
  font-size: 14px;
  padding-left: 48px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  height: 35px;
  width: 300px;
}

.react-tel-input .flag-dropdown {
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .flag-dropdown.open {
  background: #fff;
  border-radius: 3px 0 0 0;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #fff;
  border-radius: 3px 0 0 0;
}
.react-tel-input .selected-flag {
  width: 38px;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #fff;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.react-tel-input .country-list {
  width: 300px;
}
